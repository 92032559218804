.review-box {
  min-width: 400px;
}

.review-name-div h4 {
  margin: 0px;
}

.review-name-div .gray {
  color: gray;
  font-size: 15px;
}

.review-box:disabled {
  background-color: transparent !important;
}
