.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: white;
}
.loading-screen .video {
  padding: 0 30px 0 0;
  left: 50%;
  margin: 40vh auto 0 auto;
  width: 100%;
  max-width: 200px;
}
.loading-screen .video video {
  width: 200px;
}
.loading-dots {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  margin-top: 30px;
}
.loading-dots .dot {
  position: relative;
  width: 12px;
  height: 12px;
  margin: 12px;
  border-radius: 50%;
}
.loading-dots .dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: wave 2s ease-out infinite;
}
.loading-dots .dot:nth-child(1) {
  background: #0a58f6;
}
.loading-dots .dot:nth-child(1)::before {
  animation-delay: 0.2s;
}
.loading-dots .dot:nth-child(2) {
  background: #0a58f6;
}
.loading-dots .dot:nth-child(2)::before {
  animation-delay: 0.4s;
}
.loading-dots .dot:nth-child(3) {
  background: #0a58f6;
}
.loading-dots .dot:nth-child(3)::before {
  animation-delay: 0.6s;
}
.loading-dots .dot:nth-child(4) {
  background: #0a58f6;
}
.loading-dots .dot:nth-child(4)::before {
  animation-delay: 0.8s;
}
.loading-dots .dot:nth-child(5) {
  background: #0a58f6;
}
.loading-dots .dot:nth-child(5)::before {
  animation-delay: 1s;
}
@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }
  80%,
  100% {
    opacity: 0;
  }
}

.logo-loading {
  width: 200px;
}
