.article-details-div {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
}

.article-details-div .form-head-txt {
  font-size: 18px;
  color: #acadad;
}

.article-details-div .control-label {
  font-weight: 600;
  color: #acadad;
  margin-bottom: 4px;
  font-size: 14px;
}
