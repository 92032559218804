.home-head-img {
  display: block;
  max-width: 100%;
  padding: 10px;
  height: auto;
}

.home-icon {
  margin-right: 8px;
}
