:root {
  --main-color: #046fa0;
  --theme-dark-color: #004464;
  --theme-light-blue: #62c5f3;
}

body {
  background-color: aliceblue !important;
}

.btn-style-set {
  height: 40px;
  color: white;
  padding: 5px 10px;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
}

.print-btn {
  background-color: #a00404;
  border: 1px solid #a00404;
}

.invoice-email-btn {
  background-color: #040ea0;
  border: 1px solid #040ea0;
}

.new-invoice-btn {
  background-color: #04a04a;
  border: 1px solid #04a04a;
}

.button-submit-style {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: var(--main-color);
}

.button-submit-style:hover {
  background: var(--main-color);
  border: 1px solid #ffffff;
  color: white;
}

.button-submit-style-active {
  background: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
}

.button-submit-style-disable {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: var(--main-color);
  pointer-events: none;
  cursor: default;
}

.btn-link-style {
  background-color: transparent;
  border: transparent;
  color: var(--theme-dark-color);
  font-size: 20px;
  cursor: pointer;
}

.modal-head {
  background: var(--theme-dark-color);
  border: var(--theme-dark-color);
  color: white;
  font-size: 20px;
}

.modal-head button {
  background: transparent;
  border: transparent;
  color: white;
  font-size: 20px;
}

.button-add-style {
  text-align: center;
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 7px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  float: right;
  font-size: 17px;
}

.button-add-style:hover {
  background: #ffffff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.table_outer_div {
  box-shadow: 1px 1px 6px var(--theme-dark-color);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 10px;
  margin: 5px 0;
}

.table_outer_div .rdt_TableHeader {
  color: white;
  background-color: var(--theme-dark-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 17px;
}

.table_outer_div .rdt_Pagination {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table_outer_div .header-txt {
  padding: 10px 10px 10px 10px;
  color: white;
  background-color: var(--theme-dark-color);
  letter-spacing: 0.4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 17px;
}

.table td {
  padding: 0.75rem 10px;
}

.table_outer_div table {
  padding: 10px;
}
